<template>
  <svg viewBox="0 0 180 180" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="90.5" cy="90" r="70" fill="#E8EFFF" />
    <path
      d="M117.599 131.008c-5.4-1.95-15.75-3.15-27.6-3.15-17.4 0-31.499 2.7-31.499 6.15 0 3.3 14.1 7.992 31.5 7.992 17.399 0 31.499-4.542 31.499-7.992 0-1.2-1.35-2.25-3.9-3Z"
      fill="#2F80ED"
    />
    <path
      d="M71.052 104.128c-1.219 6.16-5.79 22.329-6.552 27.872 14.8 9.6 41.5 5.334 53 2-.462-3.384-3.082-22.461-4.47-29.231-.924-4.153-6.935-8.153-14.179-11.846-1.695-.923-1.85-3.384-.154-4.307 5.086-3.231 13.563-8.77 14.179-11.539 1.233-5.692 2.775-20.923 3.391-27.077-17.786-8.4-41.008-4.166-50.396-1 1.067 5.852 3.962 21.559 5.181 27.564.762 4.158 6.857 8.316 14.171 12.165 1.676.77 1.676 3.08.153 4.004-5.181 3.08-13.714 8.623-14.324 11.395Z"
      fill="#A2BDFF"
    />
    <path
      d="M89.6 89.25c-3.9-2.1-14.4-7.35-14.85-9.6C74 75.9 83.45 75 91.25 75c7.8 0 15.899 1.35 15.149 5.1-.3 1.5-9.45 7.65-12.3 9.3-.9.6.3 20.849 1.35 21.299 7.551 3.236 13.15 9.75 13.6 12 .75 3.6 1.5 9.3 1.5 9.45 0 1.5-13.35 3.75-21.15 3.75-7.65 0-21.749-2.4-21.899-3.9 0 0 1.8-6.75 3.3-10.5.6-1.5 10.7-8.999 16.85-10.5 1.05-.75 2.85-21.3 1.95-21.75Z"
      fill="#699CFF"
    />
    <path
      d="M90.2 51.96c16.402 0 29.699-2.216 29.699-4.95 0-2.733-13.297-4.95-29.7-4.95-16.402 0-29.699 2.217-29.699 4.95 0 2.734 13.297 4.95 29.7 4.95ZM63.615 36.42c-.234 0-.468 0-.819-.117l-10.652-3.98c-1.288-.585-1.756-2.107-1.17-3.395.585-1.287 2.223-1.755 3.511-1.17l9.833 5.736c.82.35 1.17 1.287.82 2.107-.235.585-.937.82-1.523.82ZM76.38 33.577c-.937 0-1.873-.469-2.341-1.405l-6.556-10.887c-.82-1.521-.234-3.511 1.288-4.33 1.522-.82 3.512-.235 4.331 1.287l5.619 11.472c.702 1.287.234 2.809-1.054 3.511-.35.234-.819.352-1.287.352ZM114.944 35.228c-.936 0-1.873-.351-2.341-.82-.585-.702 0-1.521 1.288-1.872l11.588-4.8c1.756-.585 3.863 0 4.683 1.288.819 1.287.117 2.926-1.756 3.512l-12.408 2.575c-.352 0-.703.117-1.054.117ZM92.548 27.797c-.317 1.087-.952 1.993-1.746 2.174-1.11.363-2.063-.543-2.063-2.174L85.723 12.76c-.317-2.174 1.111-4.348 3.016-4.71 1.904-.363 3.809 1.268 4.126 3.442l-.158 14.856c0 .543-.16.906-.16 1.45ZM101.651 31.567c-.117-.117-.234-.118-.468-.352-.702-.82-.702-2.34.117-3.511l6.204-9.365c.936-1.17 2.575-1.288 3.746-.351 1.17.936 1.287 2.575.351 3.746l-7.609 8.779c-.585.936-1.639 1.405-2.341 1.053Z"
      fill="#2F80ED"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'IllustrationWaiting',
});
</script>

<style lang="scss"></style>
